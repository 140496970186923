import { AppProps } from 'next/app';
import { DefaultSeo } from 'next-seo';
import { Public_Sans, IBM_Plex_Mono } from '@next/font/google';
import Head from 'next/head';
import '~/scss/index.scss';

//weight: ['300', '400', '500', '600', '700'],
//weight: ['400', '700'],
const sans = Public_Sans({
  weight: ['300', '400', '500', '600', '700'],
  subsets: ['latin'],
  variable: '--font-sans',
});

const secondary = Public_Sans({
  weight: ['300', '400', '500', '600', '700'],
  subsets: ['latin'],
  variable: '--font-secondary',
});

const mono = IBM_Plex_Mono({
  weight: ['400'],
  subsets: ['latin'],
  variable: '--font-mono',
});

/*
export function reportWebVitals(metric) {
  console.log(metric);
}
*/

export default function App({ Component, pageProps }: AppProps) {
  return (
    <div>
      <Head>
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
      </Head>
      <DefaultSeo
        themeColor="#ffffff"
        openGraph={{
          locale: 'en_US',
        }}
        twitter={{
          handle: '@_de_varela',
          cardType: 'summary',
        }}
      />
      <main
        className={[secondary.variable, mono.variable, sans.variable].join(' ')}
      >
        <Component {...pageProps} />
      </main>
    </div>
  );
}
